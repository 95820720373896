import React, { Fragment,useEffect } from 'react';
//import AddComment from './AddComment';
import { useSelector, useDispatch } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import { convertLocaleDate } from '../../../helper';
import { getReviewAction ,getReviewActionProfile} from '../../../actions/userActions';
import { useLocation } from 'react-router';
import $ from 'jquery';
import { getLastURL } from '../../../helper';
import { FormattedMessage } from 'react-intl';


let count = 1;
const Rating = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const product_id = getLastURL(location);
   
    const { details } = useSelector(state => state.user);
   
    const user = JSON.parse(sessionStorage.getItem('user'));
    const { allReview } = useSelector(state => state.AllReviewProfile);
    const handleMoreReviewNext = () => {
        count = count + 1;
        if (allReview && allReview.last_page === count) {
            $('#see-more-review-next').css({
                display: 'none'
            })
            $('#see-more-review-previous').css({
                display: 'block'
            })
        } else {
            $('#see-more-review-next').css({
                display: 'block'
            })
            $('#see-more-review-previous').css({
                display: 'block'
            })
        }
        dispatch(getReviewActionProfile(null, user.id, count))
    }


    const handleMoreReviewPrevious = () => {
        count = count - 1;
        if (count === 1) {
            $('#see-more-review-previous').css({
                display: 'none'
            })
            $('#see-more-review-next').css({
                display: 'block'
            })
        } else {
            $('#see-more-review-previous').css({
                display: 'block'
            })
            $('#see-more-review-next').css({
                display: 'block'
            })
        }
        dispatch(getReviewActionProfile(null, user.id))
    }

    return (
        <Fragment>
            <section className="rating pt-4 " id="ratings-sec" >
                <div className="container-fluid">
                    <div className="rating_top d-flex justify-content-between">
                        <div className="TitleBtns">
                            <h4 className="font-20 black fb mb-2"> <FormattedMessage id="Rating"  /></h4>
                            <div className="d-flex hwerating">
                            <h6>{allReview  && allReview.sellerInfo && allReview.sellerInfo.name}</h6>
                            <div className=" average-rating d-flex align-items-center">
                            <i style={{color:"#003B75"}} className='fa fa-star'></i>{allReview && allReview.sellerInfo && allReview.sellerInfo.average_rating && allReview.sellerInfo.average_rating.toFixed(1)}({allReview  && allReview.sellerInfo && allReview.sellerInfo.total_rating} <FormattedMessage id="ratings"  />)
                                {/* {
                                    details && details.productDetails && details.productDetails.average_rating &&
                                    <ReactStars
                                        count={5}
                                        value={details && details.productDetails && details.productDetails.average_rating}
                                        size={24}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor="#0f3b75"
                                        onChange={null}
                                    />
                                } */}
                                {/* <span className="ml-3">{details && details.productDetails && details.productDetails.total_rating} Ratings</span> */}
                                {/* <button className="btn" data-toggle="modal" data-target="#comment">Write a review</button> */}
                            </div>
                            </div>  
                        </div>

                        <div className="cus_switch d-flex align-item-center more-review">
                            {/* <button className="btn btn-black" id="see-more-review" style={{height:45}} type="button" onClick={handleMoreReview}>See More Review</button> */}
                            <span onClick={handleMoreReviewPrevious} id="see-more-review-previous" className="previous" style={{ height: 40, display: 'none' }}>&laquo; <FormattedMessage id="Previous"  /></span>
                            <span onClick={handleMoreReviewNext} id="see-more-review-next" className="next" style={{ height: 40 }}><FormattedMessage id="business.next"  /> &raquo;</span>
                        </div>

                    </div>
                    {/* <div className="row mx-0">
                        <div className="col-md-12 col-lg-7 pl-0 ratings">
                            <div className="row mx-0">
                                <div className="col-md-4 col-sm-4"><span className="Positive r_s"><i className="fa fa-smile-o"
                                    aria-hidden="true"></i></span> Positive <span className="Positive ">75%</span></div>
                                <div className="col-md-4 col-sm-4"><span className="Neutral r_s"><i className="fa fa-meh-o" aria-hidden="true"></i></span> Neutral <span className="Neutral ">20%</span></div>
                                <div className="col-md-4 col-sm-4"><span className="Negative r_s"><i className="fa fa-frown-o" aria-hidden="true"></i></span> Negative <span className="Negative ">5%</span></div>
                            </div>
                        </div>
                    </div> */}


                    <div className="row  mt-4">
                        {
                            allReview && allReview?.ratings?.data?.length && allReview?.ratings?.data.data && allReview.data.data.map((data, key) => {
                                return (
                                    <div className="col-lg-3 col-md-4 col-sm-4  ">
                                        <div className="blcks p-3">
                                        {data.is_anonymous == 1 && <h5>{data.name}</h5> }
                                            <div className="clearfix"></div>
                                            <p>{data.comment}</p>
                                            <div className="date_list d-flex justify-content-between pt-3">
                                                <span className="float-right">{convertLocaleDate(data.created_at)}</span>
                                                <span><i style={{color:"#003B75"}} className='fa fa-star'></i> &nbsp;
                                                {data && data.rating && data.rating.toFixed(1)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
           
        </Fragment>
    )
}

export default Rating
